<template>
    <div id="cesdk_container" style="height: 90vh; width: 100%">
        <div v-if="!imglyLibReady"
            style="background: rgb(204, 204, 204);display: flex;height: 100%;width: 100%;justify-content: center;align-items: center;">
            Loading</div>
    </div>
</template>


<script>
import { defaults, map } from 'lodash';

const imglyEditorURL = 'https://cdn.img.ly/packages/imgly/cesdk-js/1.40.0/cesdk.umd.js';
const defaultConfig = {
    ui: {
        elements: {
            navigation: {
                action: {
                    close: true,
                    back: true,
                    save: true,
                    load: false,
                    export: false
                }
            }
        },
        pageFormats: {
            '1080p': {
                default: true,
                width: 1920,
                height: 1080,
                unit: 'Pixel',
                fixedOrientation: true
            }
        }
    }
}
export default {
    props: { config: Object },
    data() {
        return {
            imglyLibReady: false,
            editorInitialized: false,
            pendingChanges: false,
            pendingSave: false,
            editingSlideIndex: null,
            _cesdk: null,
        }
    },
    methods: {
        async createBlankScene() {
            await this._cesdk.createDesignScene();
        },
        onUnsupportedBrowser() {
            /* This is the default window alert which will be shown in case an unsupported
             * browser tries to run CE.SDK */
            window.alert(
                'Your current browser is not supported.\nPlease use one of the following:\n\n- Mozilla Firefox 86 or newer\n- Apple Safari 14.1 or newer\n- Microsoft Edge 88 or newer\n- Google Chrome 88 or newer'
            );
        },
        onBack() {
            this.onClose();
        },
        onClose() {
            // TODO: check for unsaved changes and prompt before closing the editor
            this.$emit('close');
            this.$nextTick(() => {
                this.createBlankScene();
            });
        },
        // When user clicks a button, we check if there are any changes to blocks/scene that we need to wait for
        async onSave(scene) {
            // console.log('Performing Save');
            const activeScene = this._cesdk.engine.scene.get();
            // debugger;
            const currentPage = this._cesdk.engine.scene.getCurrentPage();
            // const maxExportSizeInPixels = this._cesdk.engine.editor.getMaxExportSize();
            const mimeType = 'image/png';
            // const options = { pngCompressionLevel: 8, targetWidth: 960, targetHeight: 540 };
            const options = { pngCompressionLevel: 8 };

            const blob = await this._cesdk.engine.block.export(activeScene, mimeType, options);
            this.$emit('onSave', { scene, blob, slideIndex: this.editingSlideIndex });

            this.$nextTick(async () => {
                this.createBlankScene();
                this.editingSlideIndex = null;
            });
        },
        onLoad() {
            console.log('onLoad stub method');
            // const scene = '...'; // Fill with scene
            // return Promise.resolve(scene);
        },
        onUpload(file, onProgress) {
            console.log('onUpload stub method');
            // const newImage = {
            //     id: 'exampleImageIdentifier',
            //     meta: {
            //         uri: 'https://YOURSERVER/images/file.jpg',
            //         thumbUri: 'https://YOURSERVER/images/thumb.jpg'
            //     }
            // };
            // return Promise.resolve(newImage);
        },
        async loadScene(sceneData, slideIndex) {
            if (typeof slideIndex === 'number' && slideIndex > -1) {
                this.editingSlideIndex = slideIndex;
            }
            await this._cesdk.engine.scene.loadFromString(sceneData);
        },
        async createSceneFromImageURL(imgURL, slideIndex) {
            if (typeof slideIndex === 'number' && slideIndex > -1) {
                this.editingSlideIndex = slideIndex;
            }
            await this._cesdk.engine.scene.createFromImage(imgURL);
        },
        async init() {
            let scriptPromise;
            if (!document.getElementById('imglyEditor')) {
                console.log('CreativeEditor component embedding the imgly script');
                // DX HMR FIx: If the script isn't on the document anymore then clear out these flags too.
                this.imglyLibReady = false;
                this.editorInitialized = false;

                scriptPromise = new Promise((resolve) => {
                    const imglyLib = document.createElement('script');
                    imglyLib.src = imglyEditorURL;
                    imglyLib.id = 'imglyEditor';
                    imglyLib.onload = () => {
                        // console.log('imgly editor loaded from CDN');
                        resolve();
                        this.imglyLibReady = true;
                    }
                    document.getElementsByTagName('head')[0].appendChild(imglyLib);
                })
            } else {
                console.log('CreativeEditor component imgly script already available', this);
                if (!this.imglyLibReady) {
                    // DX HMR FIx: If the script isn't on the document anymore then clear out these flags too.
                    this.imglyLibReady = true;
                    this.editorInitialized = false;
                }
                scriptPromise = Promise.resolve();
            }
            return scriptPromise.then(() => {
                console.log('CreativeEditor component begin init');
                let initPromise;
                if (!this.editorInitialized) {
                    let ceConfig = {
                        license: process.env.VUE_APP_IMGLY_CESDK_KEY,
                        callbacks: {
                            onUnsupportedBrowser: this.onUnsupportedBrowser,
                            onBack: this.onBack,
                            onClose: this.onClose,
                            onSave: this.onSave,
                            onLoad: this.onLoad,
                            onUpload: this.onUpload
                        },
                        // mash together defaults from above and settings passed in via $props
                        ...defaults(defaultConfig, this.Config || {})
                    };
                    initPromise = CreativeEditorSDK.create('#cesdk_container', ceConfig)
                    
                } else {
                    initPromise = new Promise((resolve) => {
                        resolve(this._cesdk);
                    });
                }
                return initPromise.then(async (instance) => {
                    if (!this._cesdk) {
                        this._cesdk = instance;
                        // Do something with the instance of CreativeEditor SDK, for example:
                        // Remove ability to add pages.
                        instance.feature.enable('ly.img.page.add', false);
                        // Populate the asset library with default / demo asset sources.
                        instance.addDefaultAssetSources();
                        instance.addDemoAssetSources({ sceneMode: 'Design' });
                    }
    
                    this.editorInitialized = true;
                });   
            })
        }
    },
    watch: {},
    beforeUnmount() {
        // console.log('destroying the imgly editor.');
        if (this._cesdk) {
            this._cesdk.dispose();
            this._cesdk = null;
        }
    }
};
</script>
